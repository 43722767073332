import React, {useState} from "react";
import { Seo } from "../components/Seo";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clsx from "clsx";

const CaretDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-2.5 ml-2 mt-2.5"><path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z"/></svg>
);

const CaretUp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="w-2.5 ml-2 mt-2.5"><path d="M9.39 265.4l127.1-128C143.6 131.1 151.8 128 160 128s16.38 3.125 22.63 9.375l127.1 128c9.156 9.156 11.9 22.91 6.943 34.88S300.9 320 287.1 320H32.01c-12.94 0-24.62-7.781-29.58-19.75S.2333 274.5 9.39 265.4z"/></svg>
);

const getItemStyle = ({
  text_size,
  bold_text,
  new_line
}) => {
  switch (text_size) {
    case 'Heading':
      return clsx({
        'font-black': bold_text,
        'block mb-8': new_line
      }, 'text-5xl lg:text-6xl')
    case 'Subheading':
      return clsx({
        'font-black': bold_text,
        'block mb-8': new_line
      }, 'text-3xl lg:text-4xl')
    case 'Caption': 
      return clsx({
        'font-black': bold_text,
        'block': new_line
      }, 'text-2xl lg:text-3xl')
    case 'Paragraph':
    default:

      return clsx({
        'font-bold': bold_text,
        'block mt-1 mb-8': new_line
      }, 'text-lg md:text-xl')
  }
}

const EnterprisePage = ({data}) => {
  const [selectedDropItem, setSelectedDropItem] = useState(0);
  const prismicData = data.prismicEnterprise.data;
  return (
    <main>
      <section>
        <div className="relative overflow-hidden w-full h-full py-24">
          <div className="absolute block w-full top-0 left-0 h-full">
            <GatsbyImage
              image={getImage(prismicData.banner_image)}
              alt={prismicData.banner_image.alt || ""}
              className="block w-full h-full"
            />
          </div>
          <div className="absolute w-full top-0 left-0 h-full bg-black/30"></div>
          <div className="w-full h-full">
            <div className="relative xl:container flex flex-wrap mx-auto w-full h-full items-center">
              <div className="w-full lg:w-3/5 px-6">
                {prismicData.banner_title_parts && (
                  <p className="text-5xl md:text-7xl font-black mb-8 md:mb-10">
                    {prismicData.banner_title_parts.map((i, k) => (
                      <span
                        style={{ color: i.color }}
                        key={k}
                        className={clsx({
                          "block mb-12": i.new_line,
                        })}
                      >
                        {i.part}{" "}
                      </span>
                    ))}
                  </p>
                )}
                <p className="text-3xl md:text-4xl font-black text-white mb-8">{prismicData.subtitle}</p>
                {prismicData.paragraphs.map((i, k) => (
                  <p className="text-xl md:text-2xl text-white mb-6">{i.text.text}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 grid md:grid-cols-2 gap-y-8">
          <div className="md:pr-32">
            <p className="text-4xl md:text-5xl font-black text-black">{prismicData.section_two_title}</p>
          </div>
          <div>
            {prismicData.section_two_list.map((i,k) => {
              const active = k === selectedDropItem;
              return (
                <div className="mb-3"
                  key={k}>
                  <span className={clsx("text-2xl md:text-3xl font-black flex flex-row items-center cursor-pointer", active ? 'text-sourci-teal' : 'text-black')}
                     onClick={() => setSelectedDropItem(k)}
                     onKeyDown={() => setSelectedDropItem(k)}
                     role="button"
                     tabIndex="0">{i.title} {active
                          ? <CaretUp />
                          : <CaretDown />}</span>
                  {active && <p>{i.description.text}</p>}
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="xl:container mx-auto px-6">
          <div className="text-center mb-12">
            <h3 className="text-3xl md:text-4xl font-black">Organisations that we <span className="text-sourci-pink">proudly support.</span></h3>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-x-12 xl:gap-x-36 gap-y-10 items-center">
            {prismicData.logos.map((i,k) => (
              <div className="items-center justify-center flex"
                key={k}>
                <GatsbyImage image={getImage(i.image)}
                  alt={i.image.alt || ''}
                  className="mx-auto xl:max-w-[200px]" />
              </div>
            ))}
          </div>
        </div>
      </section>

      {prismicData.body.map((section, k) => (
        <section className="py-24"
          key={k}
          style={{backgroundColor: section.primary.background_color}}>
            <div className="xl:container mx-auto px-6 grid lg:grid-cols-2 items-start gap-x-12 gap-y-12">
              <div className="md:pr-24">
                <h2 className="font-black text-black text-4xl md:text-5xl">{section.primary.project_title}</h2>
                <hr className="my-8 bg-sourci-teal h-[3px]" />
                <p className="font-bold text-sourci-primary text-3xl md:text-4xl mb-12" style={{color: section.primary.project_color}}>{section.primary.project_description.text}</p>
                <p className="font-black text-black text-3xl md:text-4xl mb-1">Project Value:</p>
                <p className="font-black  text-4xl md:text-5xl text-sourci-teal">{section.primary.project_value}</p>
              </div>
              <div>
                {section.items.map((i,k) => (
                  <React.Fragment key={k}>
                    <span className={clsx(getItemStyle({...i}))}
                      style={{color: i.text_color}}> {i.text.text} </span>
                    {i.divider && <hr className={clsx('w-[calc(100%+3rem)] border mb-8', section.primary.image_position === 'Left' && '-ml-12')} />}
                  </React.Fragment>
                ))}
              </div>

            </div>
        </section>
      ))}
    </main>
  );
};

export default EnterprisePage;

export const query = graphql`
  query {
    prismicEnterprise {
      data {
        subtitle
        section_two_title
        section_two_list {
          description {
            text
          }
          title
        }
        paragraphs {
          text {
            text
          }
        }
        meta_title
        meta_description
        logos {
          image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        body {
          ... on PrismicEnterpriseDataBodyEnterpriseProjects {
            id
            items {
              bold_text
              divider
              new_line
              text_color
              text_size
              text {
                text
              }
            }
            primary {
              background_color
              project_color
              project_description {
                text
              }
              project_title
              project_value
            }
          }
        }
        banner_title_parts {
          part
          new_line
          color
        }
        banner_image {
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`;

export function Head({ data }) {
  const prismicData = data.prismicEnterprise.data;
  return (
    <Seo
      title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/enterprise"
    />
  );
}
